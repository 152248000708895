type DailyTransfer = {
  _id: string;
  date: Date;
  manager: Manager;
  isPaid: boolean;
  totalAmount: {
    eur: number;
    usdt: number;
  };
  netAmount: {
    eur: number;
    usdt: number;
  };
  fees: {
    percentage: number;
    eur: number;
    usdt: number;
  };
  createdAt: Date;
  updatedAt: Date;

  transactions?: Transaction[];

  // This property is specifically included on the frontend to handle the loading state of the 'isPaid' toggler in the view during payment status updates.
  isUpdatingPaymentStatus?: boolean;
};

type Manager = {
  _id: string;
  email: string;
  company: Company;
};

type Company = {
  _id: string;
  email: string;
};

type Transaction = {
  _id: string;
  status: TransactionStatus;
  amount: {
    eur: number;
    usdt: number;
  };
  fees: {
    percentage: number;
    eur: number;
  };
  createdAt: Date;
  refund?: Refund;
};

enum TransactionStatus {
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  REFUNDING = 'REFUNDING',
  FULL_REFUNDED = 'FULL_REFUNDED'
}

type Refund = {
  _id: string;
  refundedAmount: {
    percentage: number;
    eur: number;
    usdt: number;
  };
  remainingAmount: {
    eur: number;
    usdt: number;
  };
  fees: {
    eur: number;
    usdt: number;
  };
};

export { DailyTransfer, Transaction, TransactionStatus, Refund };
