/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ApiResponse } from '@core/interfaces/api-response';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

/* -------------------------------------------------------------------------- */
/*                                  Service                                   */
/* -------------------------------------------------------------------------- */

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(private http: HttpClient) {}

  /**
   * Uploads a document to Google Cloud.
   * @param doc - The document to upload.
   * @param doc.name - The name of the document.
   * @param doc.file - The base64-encoded content of the document.
   */
  upload(doc: { name: string; file: string }): Observable<string> {
    return this.http
      .post<ApiResponse>(environment.apiURL + `upload/document`, doc)
      .pipe(map((res: ApiResponse) => res.data));
  }

  /**
   * Downloads a document.
   * @param docUrl - The URL of the document to download.
   */
  download(docUrl: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(docUrl, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }
}
