/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

// Services
import { AuthService } from '@core/services/auth/auth.service';
import { ImpersonationService } from '@core/services/impersonation/impersonation.service';
import { CryptoService } from '@core/services/crypto/crypto.service';

// Others
import { environment } from '@environments/environment';

// Components
import { ImpersonationSigninComponent } from '@public/impersonation-signin/impersonation-signin.component';

// Types
import { ImpersonationQueryParams } from '@shared/types/impersonation';

/* -------------------------------------------------------------------------- */
/*                                 Component                                  */
/* -------------------------------------------------------------------------- */

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {
  appVersion = environment.appVersion;

  signInForm: UntypedFormGroup;
  isLoading = false;

  // Subs
  private routerEventsSub$: Subscription = new Subscription();

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private impersonationService: ImpersonationService,
    private cryptoService: CryptoService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      // Load encrypted query params
      const encryptedParams: string = params[this.impersonationService.sessionParamName];

      if (encryptedParams) {
        // Decrypt query params
        const queryParams: ImpersonationQueryParams = this.cryptoService.decrypt(encryptedParams);

        // Case: impersonation query params loaded => login as manager
        if (queryParams) {
          this.displayImpersonationLoginDialog(queryParams);
        }
      }
    });

    this.signInForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      password: new UntypedFormControl(null, Validators.required)
    });
  }

  get email(): AbstractControl {
    return this.signInForm.get('email');
  }

  get password(): AbstractControl {
    return this.signInForm.get('password');
  }

  onSignIn(): void {
    if (!this.signInForm.valid || this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.authService
      .signin(this.signInForm.value)
      .pipe(
        tap(({ user, token }) => {
          // token is retrieved, user is logged in!
          if(token) {
            switch (user.role) {
              case 'Association':
                this.router.navigate(['city']);
                break;
              case 'Manager':
              case 'SuperManager':
                this.router.navigate(['backoffice']);
                break;
              case 'SuperAdmin':
                this.router.navigate(['admin']);
                break;
            }
          }
          // No token ? Go perform the OTP:
          else {
            this.router.navigate(['otp'], {state: {"user": user}});
          }
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  displayImpersonationLoginDialog(queryParams: ImpersonationQueryParams): void {
    // open dialog
    this.matDialog.open(ImpersonationSigninComponent, {
      width: '500px',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      data: {
        queryParams
      }
    });
  }

  impersonate(): void {}

  private unsubscribe(): void {
    this.routerEventsSub$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
