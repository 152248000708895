/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

// Services
import { DocumentService } from '@shared/services/document/document.service';

// Components
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

/* -------------------------------------------------------------------------- */
/*                                 Component                                  */
/* -------------------------------------------------------------------------- */

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit, OnDestroy {
  // Boolean
  @Input() isRequired: boolean = false;
  @Input() isDirty: boolean = true;
  isDownloading: boolean = false;

  // String
  @Input() inputLabel: string;
  fileName: string;
  @Input() fileUrl: string;
  @Input() allowedExtensions: string;
  uploadTooltipMessage: string;
  fileExtension: string = '';

  // EventEmitter
  @Output() newFileEmit: EventEmitter<{ file: string; extension: string }> = new EventEmitter<{
    file: string;
    extension: string;
  }>();

  // Subs
  private docDownloadSub$: Subscription = new Subscription();

  constructor(private cdr: ChangeDetectorRef, private dialog: MatDialog, private docService: DocumentService) {}

  ngOnInit(): void {
    this.extractFileNameFromUrl();

    // set upload tooltip message based on the allowed extensions
    this.uploadTooltipMessage = `Selectionner un nouveau fichier ( les formats acceptés: ${this.allowedExtensions})`;
  }

  /**
   *
   * Extracts the file name from a given file URL.
   */
  extractFileNameFromUrl(): void {
    if (this.fileUrl) {
      const parts: string[] = this.fileUrl.split('/');
      let lastPart: string = parts[parts.length - 1];

      // Remove access token query param if it exists
      const index = lastPart.indexOf('?access_token');
      if (index !== -1) lastPart = lastPart.substring(0, index);

      // Case: file name contains extension
      if (lastPart.split('.').length > 1) {
        this.fileName = lastPart.split('.')[0];
        this.fileExtension = lastPart.split('.')[1];
        return;
      }

      // Case: file name does not contain extension
      this.fileName = lastPart;
      return;
    }
  }

  /**
   * Handles the change event when a file is selected for upload.
   *
   * @param event - The change event triggered when a file is selected.
   *
   */
  uploadFile(event: Event & { target: { files: FileList } }) {
    const reader = new FileReader();
    const uploadedFile = event.target.files[0] as File;

    if (!uploadedFile) {
      return;
    }

    const maxSizeInBytes: number = 5 * 1024 * 1024; // 5 MB in bytes

    if (uploadedFile.size > maxSizeInBytes) {
      this.dialog.open(AlertDialogComponent, {
        panelClass: 'fy-dialog',
        hasBackdrop: true,
        disableClose: true,
        autoFocus: false,
        data: {
          alertMessage:
            'Veuillez noter que la taille maximale autorisée pour le fichier est de 5 Mo. Merci de vous assurer que votre fichier respecte cette limite',
          buttonText: 'Fermer'
        }
      });

      return;
    }

    // Get file name
    this.fileName = uploadedFile.name.split('.').shift();
    this.fileExtension = uploadedFile.type.split('/').pop();

    reader.readAsDataURL(uploadedFile);

    // Process the file when it's loaded
    reader.onload = () => {
      this.fileUrl = reader.result as string;

      this.newFileEmit.emit({
        file: this.fileUrl.split(',').pop(),
        extension: this.fileExtension
      });
    };

    // Detect changes since file is loading outside ngZone
    this.cdr.markForCheck();
  }

  /**
   *
   * Initiates the download process for a file.
   */
  downloadFile() {
    if (this.fileUrl) {
      this.isDownloading = true;

      // Case: The URL is an external link (e.g., a storage URL). => // Open the external link in a new window.
      if (!this.fileUrl.includes('/download')) {
        const link = document.createElement('a');
        link.href = this.fileUrl;
        link.target = '_blank';
        link.click();
        this.isDownloading = false;
        return;
      }

      // Case: The URL is an internal link (requires user authentication). => api call
      this.docDownloadSub$ = this.docService.download(this.fileUrl).subscribe((response: HttpResponse<Blob>) => {
        this.handleDownloadResponse(response);
      });
    }
  }

  /**
   * Handles the download response for a file by processing the HttpResponse with a Blob payload.
   *
   * @param response - The HTTP response with a Blob payload.
   *
   */
  private handleDownloadResponse(response: HttpResponse<Blob>): void {
    const fileName = this.fileName || 'download';
    const contentType = response.headers.get('Content-Type') || 'application/octet-stream';

    const blob = new Blob([response.body], { type: contentType });

    // Download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    //  Cleaning dom
    document.body.removeChild(link);

    this.isDownloading = false;
  }

  private unsubscribe(): void {
    this.docDownloadSub$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
