import { Component, Input } from '@angular/core';

import { NavLink } from '@city/models/navbar.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() name: string;
  @Input() navLinks: NavLink[];
}
