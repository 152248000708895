/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Interface
import { ApiResponse } from '@core/interfaces/api-response';

// Types
import { NotificationChannels } from '@shared/types/notification';

// Others
import { environment } from '@environments/environment';

/* -------------------------------------------------------------------------- */
/*                                  Service                                   */
/* -------------------------------------------------------------------------- */

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  /**
   *
   * Get a list of connected user notifications.
   */
  getList(): Observable<any> {
    return this.http
      .get<ApiResponse>(environment.apiURL + `users/notifications/me?channel=${NotificationChannels.BACK_OFFICE}`)
      .pipe(map((response: ApiResponse) => response.data));
  }

  /**
   *
   * Mark connected user notifications as seen.
   */
  markAsSeen(): Observable<any> {
    return this.http
      .put<ApiResponse>(environment.apiURL + `users/notifications/me/seen`, {
        channel: NotificationChannels.BACK_OFFICE
      })
      .pipe(map((response: ApiResponse) => response.success));
  }
}
