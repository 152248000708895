/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

// Services
import { DocumentService } from '@shared/services/document/document.service';

/* -------------------------------------------------------------------------- */
/*                                 Component                                  */
/* -------------------------------------------------------------------------- */

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit, OnDestroy {
  // Boolean
  isDownloading: boolean = false;

  // String
  @Input() label: string;
  @Input() fileUrl: string;
  fileName: string;
  fileExtension: string = '';

  // Subs
  private docDownloadSub$: Subscription = new Subscription();

  constructor(private docService: DocumentService) {}

  ngOnInit(): void {
    this.extractFileNameFromUrl();
  }

  /**
   *
   * Extracts the file name from a given file URL.
   */
  extractFileNameFromUrl(): void {
    if (this.fileUrl) {
      const parts: string[] = this.fileUrl.split('/');
      let lastPart: string = parts[parts.length - 1];

      // Remove access token query param if it exists
      const index = lastPart.indexOf('?access_token');
      if (index !== -1) lastPart = lastPart.substring(0, index);

      // Case: file name contains extension
      if (lastPart.split('.').length > 1) {
        this.fileName = lastPart.split('.')[0];
        this.fileExtension = lastPart.split('.')[1];
        return;
      }

      // Case: file name does not contain extension
      this.fileName = lastPart;
      return;
    }
  }

  /**
   *
   * Initiates the download process for a file.
   */
  downloadFile() {
    if (this.fileUrl) {
      this.isDownloading = true;

      // Case: The URL is an external link (e.g., a storage URL). => // Open the external link in a new window.
      if (!this.fileUrl.includes('/download')) {
        const link = document.createElement('a');
        link.href = this.fileUrl;
        link.target = '_blank';
        link.click();
        this.isDownloading = false;
        return;
      }

      // Case: The URL is an internal link (requires user authentication). => api call
      this.docDownloadSub$ = this.docService.download(this.fileUrl).subscribe((response: HttpResponse<Blob>) => {
        this.handleDownloadResponse(response);
      });
    }
  }

  /**
   * Handles the download response for a file by processing the HttpResponse with a Blob payload.
   *
   * @param response - The HTTP response with a Blob payload.
   *
   */
  private handleDownloadResponse(response: HttpResponse<Blob>): void {
    const fileName = this.fileName || 'download';
    const contentType = response.headers.get('Content-Type') || 'application/octet-stream';

    const blob = new Blob([response.body], { type: contentType });

    // Download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    //  Cleaning dom
    document.body.removeChild(link);

    this.isDownloading = false;
  }

  private unsubscribe(): void {
    this.docDownloadSub$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
