/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Interface
import { ApiResponse } from '@core/interfaces/api-response';

// Others
import { environment } from '@environments/environment';

/* -------------------------------------------------------------------------- */
/*                                  Service                                   */
/* -------------------------------------------------------------------------- */

@Injectable({
  providedIn: 'root'
})
export class DailyTransferService {
  constructor(private http: HttpClient) {}

  /**
   *
   * Get a list of daily transfers.
   * @param page - The page number. (Default: 0)
   * @param limit - The maximum number of daily transfers to fetch per page. (Default: 10)
   * @param {string} managerEmail - Email of the associated manager to filter based on it.
   * @param isPaid - Optional transfer payment status.
   */
  getList(page: number = 0, limit: number = 10, managerEmail: string = '', isPaid?: boolean): Observable<any> {
    let queryParams: string = `?page=${page}&limit=${limit}&managerEmail=${managerEmail}`;

    if (typeof isPaid === 'boolean') queryParams += `&isPaid=${isPaid}`;

    return this.http
      .get<ApiResponse>(environment.apiURL + `super-admins/daily-transfers${queryParams}`)
      .pipe(map((response: ApiResponse) => response.data));
  }

  /**
   *
   * Get a daily transfer details.
   * @param id - The daily transfer unique ID
   */
  getDetails(id: string): Observable<any> {
    return this.http
      .get<ApiResponse>(environment.apiURL + `users/daily-transfers/${id}`)
      .pipe(map((response: ApiResponse) => response.data));
  }

  /**
   *
   * Update a given daily transfer payment status.
   * @param id - The daily transfer unique ID
   * @param isPaid - The new payment status.
   *  */
  updatePaymentStatus(id: string, isPaid: boolean, autoTransfer = false): Observable<any> {
    return this.http
      .put<ApiResponse>(environment.apiURL + `super-admins/daily-transfers/${id}`, {
        isPaid,
        autoTransfer
      })
      .pipe(map((response: ApiResponse) => response.success));
  }

  /**
   *
   * Download a list of daily transfers in Excel format.
   * @param  startDate - Start date for the daily transfers data.
   * @param  endDate - End date for the daily transfers data.
   */
  download(startDate?: string, endDate?: string) {
    let queryParams: string = '';
    if (startDate && endDate) queryParams = `?startDate=${startDate}&endDate=${endDate}`;

    return this.http.get(environment.apiURL + `super-admins/daily-transfers/download${queryParams}`, {
      responseType: 'blob' as 'json'
    });
  }
}
