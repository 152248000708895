import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  @Input() imageTitle: string;
  @Input() imageUrl: any;
  @Input() imageLoader: boolean;
  @Input() disabled: boolean;
  @Input() type: string;

  @Output() newLogoEmit = new EventEmitter<any>();

  imageName: string;
  hint: string;
  width: string;

  constructor(private cdr: ChangeDetectorRef, private dialog: MatDialog, private translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('shared.components.upload-image.tooltip').subscribe((tooltip) => {
      switch (this.type) {
        case 'offer':
          this.hint = tooltip.offer;
          this.width = '133.33px';
          break;
        default:
          this.hint = tooltip.company;
          this.width = '100px';
          break;
      }
    });
  }

  /**
   * Upload Image
   * @param event: Event
   */
  uploadImage(event) {
    const reader = new FileReader(); // HTML5 FileReader API
    const file = event.target.files[0] as File;

    // Get file name
    if (!file) {
      return;
    }
    if (file.size > 5242880) {
      this.translate.get('shared.components.upload-image.file-size').subscribe((warning) => {
        this.dialog.open(AlertDialogComponent, {
          panelClass: 'fy-dialog',
          hasBackdrop: true,
          disableClose: true,
          autoFocus: false,
          data: {
            alertMessage: warning.message,
            buttonText: warning.action
          }
        });
      });
      return;
    } else {
      // Get file name
      this.imageName = file.name.split('.').shift();
      // When file uploads set it to file form control
      reader.onload = () => {
        this.imageUrl = reader.result;
        // Emit image value to the parent form
        // Need to split image data def 'data:image/png;base64,'
        this.newLogoEmit.emit({
          name: this.imageName,
          file: this.imageUrl.split(',').pop()
        });
      };
      // Read file
      reader.readAsDataURL(file);
      // Detect changes since file is loading outside ngZone
      this.cdr.markForCheck();
    }
  }
}
