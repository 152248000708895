import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return;
    }

    const convertedString = value.trim();

    return convertedString.charAt(0).toUpperCase() + convertedString.slice(1);
  }
}
