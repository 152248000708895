export const environment = {
  production: true,
  name: 'production',
  apiURL: 'https://api.lyzi.fr/api/next/',
  loyaltyApiURL: 'https://api.lyzi.fr/loyalty/api/',
  googleMapsApiKey: 'AIzaSyDrZs3KRLExlaU4KAy6qcuOW7nLEW9VEMA',
  stripePublicKey: 'pk_live_sF43iQtzAW0zNqBVcV4NC4EL00z8WzkVGD',
  appVersion: '2.42.3',
  brokerApiURL: 'https://stage-gpos-licence.digital-broker.io',
  zebitexApiURL: 'https://lizy-prod-1.digital-broker.net',
  firebase: {projectId:"zbxwallet",appId:"1:939646386932:web:c581b8269f050f2f0ed4a1",storageBucket:"zbxwallet.appspot.com",apiKey:"AIzaSyDmRJkEd40VTgp2JghwmjpOmhgUrV1cc_A",authDomain:"zbxwallet.firebaseapp.com",messagingSenderId:"939646386932",measurementId:"G-T1LJEPSTP0"},
  lyziCryptoSecret: 'DeonfsTfdcqn97f7JKlTxhzfOeNbAWz4'
};
