import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that truncates a string.
 *
 * Use this pipe like so: {{ String expression | truncate:10 }}
 * This truncates the string to the specified limit and adds '...' to the end if needed.
 *
 * @example
 * // Returns 'Hello...'
 * 'Hello World' | truncate:5
 *
 */
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  /**
   * Transforms the input string by truncating it to the specified limit and adding '...' if needed.
   *
   * @param value - The input string to be truncated.
   * @param limit - The maximum length of the truncated string.
   * @returns The truncated string.
   *
   * @memberof TruncatePipe
   * @implements PipeTransform
   */

  transform(value: string, limit: number): string {
    return value.length < limit ? value : value.slice(0, limit) + '...';
  }
}
