import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  // Date
  @Input() startDateInitialValue: Date;
  @Input() endDateValInitialValue: Date;

  startDate: Date;
  endDate: Date;

  // Func
  @Output() dateRangeChange = new EventEmitter<{ startDate: Date; endDate: Date }>();

  // Obj
  pickers: Record<string, string> = {
    START_DATE: 'START',
    END_DATE: 'END'
  };

  constructor() {}

  ngOnInit(): void {
    this.startDate = this.startDateInitialValue;
    this.endDate = this.endDateValInitialValue;
  }

  selectDate(picker: string, event: MatDatepickerInputEvent<Date>): void {
    picker === this.pickers.START_DATE ? (this.startDate = event.value) : (this.endDate = event.value);
    this.dateRangeChange.emit({ startDate: this.startDate, endDate: this.endDate });
  }
}
