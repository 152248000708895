/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib depenedencies
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// Types
import { DailyTransfer, TransactionStatus } from '@admin/daily-transfers/types/daily-transfer';

// Services
import { DailyTransferService } from '@admin/daily-transfers/services/daily-transfer/daily-transfer.service';

/* -------------------------------------------------------------------------- */
/*                                 Component                                  */
/* -------------------------------------------------------------------------- */

@Component({
  selector: 'app-daily-transfer-details',
  templateUrl: './daily-transfer-details.component.html',
  styleUrls: ['./daily-transfer-details.component.scss']
})
export class DailyTransferDetailsComponent implements OnInit, OnDestroy {
  // Boolean
  isLoading: boolean = true;

  // Subs
  private loadDailyTransferSub$: Subscription = new Subscription();

  // Others
  dailyTransfer: DailyTransfer;
  displayedOrderStatus = {
    PAID: 'Payé',
    REFUNDED: 'Partiellement remboursé',
    REFUNDING: 'Remboursement (en attente)',
    FULL_REFUNDED: 'Totalement remboursé'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private dailyTransferService: DailyTransferService
  ) {}

  ngOnInit(): void {
    if (this.data.id) {
      this.loadDailyTransfer();
    }
  }

  /**
   * Load daily transfer data
   */
  private loadDailyTransfer(): void {
    this.loadDailyTransferSub$ = this.dailyTransferService.getDetails(this.data.id).subscribe({
      next: (data: any) => {
        this.dailyTransfer = {
          ...data,
          transactions: []
        };

        // Format transactions data
        this.dailyTransfer.transactions = data.transactions.map((transaction: any) => {
          const modifiedOrder = {
            _id: transaction._id,
            status: transaction?.order?.status,
            amount: {
              eur: transaction?.order?.fromAmount,
              usdt: transaction?.order?.toAmount
            },
            fees: {
              percentage: transaction?.order?.fees?.percentage,
              eur: transaction?.order?.fees?.total
            },
            createdAt: transaction.createdAt,
            refund: transaction?.order?.refund
          };

          // Case: order with refunding status and refunded amount > 0 => note it as a partial refunded order
          if (
            transaction?.order?.status === TransactionStatus.REFUNDING &&
            transaction?.order?.refund &&
            transaction?.order?.refund?.refundedAmount.eur > 0 &&
            transaction?.order?.refund?.refundedAmount.eur < modifiedOrder.amount.eur
          ) {
            modifiedOrder.status = TransactionStatus.REFUNDED;
          }

          // Case: order with refunded status and without an associated refund doc => not it as full refunded
          if (transaction?.order?.status === TransactionStatus.REFUNDED && !transaction?.order?.refund) {
            modifiedOrder.status = TransactionStatus.FULL_REFUNDED;
          }

          return modifiedOrder;
        });

        this.isLoading = false;
      },
      error: () => (this.isLoading = false)
    });
  }

  private unsubscribe(): void {
    this.loadDailyTransferSub$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
