/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { Component, HostListener, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

// Services
import { NotificationService } from '@shared/services/notification/notification.service';

// Types
import { Notification, NotificationTypes } from '@shared/types/notification';

// Component
import { DailyTransferDetailsComponent } from '../daily-transfer-details/daily-transfer-details.component';

/* -------------------------------------------------------------------------- */
/*                                 Component                                  */
/* -------------------------------------------------------------------------- */

const detailsComponents = {
  [NotificationTypes.DAILY_TRANSFER_SUCCESSFUL_PAYMENT]: DailyTransferDetailsComponent,
  [NotificationTypes.DAILY_TRANSFER_CANCELLED_PAYMENT]: DailyTransferDetailsComponent
};

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements OnInit, OnDestroy {
  // Boolean
  isLoading: boolean = true;
  menuIsOpened: boolean = false;
  badgeIsDisplayed: boolean = false;
  isMarkingNotifsAsSeen: boolean = false;
  notifDetailsIsDisplayed: boolean = false;

  // Numbers
  unseenNotificationsCount: number = 0;
  notificationsCount: number = 0;

  // Lists
  notifications: Notification[];

  // Subs
  private intervalSub$: Subscription = new Subscription();
  private loadNotificationsSub$: Subscription = new Subscription();
  private markNotifsAsSeenSub$: Subscription = new Subscription();
  private detailsDialogAfterClose$: Subscription = new Subscription();

  constructor(private notificationService: NotificationService, private el: ElementRef, private matDialog: MatDialog) {}

  // Listening for an event click out side this component to close the notifications menu if its open
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    // Case: user clicks out side the box while its open
    if (!this.el.nativeElement.contains(event.target) && this.menuIsOpened && !this.notifDetailsIsDisplayed) {
      // Close menu
      this.menuIsOpened = !this.menuIsOpened;

      // Unseen notifications exists
      if (this.unseenNotificationsCount) {
        // Mark notifications As seen on the server side
        this.markNotifsAsSeenOnAPI();

        // Mark notifications as seen on the client side immediately, without waiting for the next interval to fetch and update the notifications list.
        this.markNotifsAsSeenInUI();
      }
    }
  }

  ngOnInit(): void {
    // Fetch notifications on the 1st render
    this.loadNotifications();

    // Fetch notifications every 35 sec
    this.intervalSub$ = interval(35000).subscribe(() => {
      this.loadNotifications();
    });
  }

  /**
   * Load connected user notifications list
   */
  loadNotifications(): void {
    this.loadNotificationsSub$ = this.notificationService.getList().subscribe({
      next: ({
        notificationsCount,
        unseenNotificationsCount,
        notifications
      }: {
        notificationsCount: number;
        unseenNotificationsCount: number;
        notifications: Notification[];
      }) => {
        this.notifications = notifications;
        this.notificationsCount = notificationsCount;

        // Case: current badge is hidden + received unseen notifications => display badge
        if (
          !this.isMarkingNotifsAsSeen &&
          !this.badgeIsDisplayed &&
          !this.unseenNotificationsCount &&
          unseenNotificationsCount
        ) {
          this.unseenNotificationsCount = unseenNotificationsCount;
          this.badgeIsDisplayed = true;
        }

        // Case: current badge is visible + received more unseen notifications => update unseen notif num => update badge value
        if (
          !this.isMarkingNotifsAsSeen &&
          this.badgeIsDisplayed &&
          this.unseenNotificationsCount &&
          unseenNotificationsCount &&
          this.unseenNotificationsCount !== unseenNotificationsCount
        ) {
          this.unseenNotificationsCount = unseenNotificationsCount;
        }

        // Case: current badge is visible + received 0 unseen notifications, indicating that the unseen notifications were deleted (via API) => hide the badge.
        if (!this.isMarkingNotifsAsSeen && this.badgeIsDisplayed && !unseenNotificationsCount) {
          this.hideBadge();
        }

        // Stop the skeleton loader
        if (this.isLoading) this.isLoading = false;
      }
    });
  }

  /**
   * Hide badge and reset its value to 0 when the animation is done
   */
  hideBadge(): void {
    // Hide badge
    this.badgeIsDisplayed = false;

    // Reset unseen notifications count after badge animation is done
    setTimeout(() => {
      this.unseenNotificationsCount = 0;
    }, 500);
  }

  /**
   * Mark notifications as seen ( server side )
   */
  markNotifsAsSeenOnAPI(): void {
    if (this.unseenNotificationsCount) {
      this.isMarkingNotifsAsSeen = true;
      this.hideBadge();

      this.markNotifsAsSeenSub$ = this.notificationService.markAsSeen().subscribe({
        next: (isSuccess: boolean) => {
          if (isSuccess) this.isMarkingNotifsAsSeen = false;
        },
        error: () => (this.isMarkingNotifsAsSeen = false)
      });
    }
  }

  /**
   * Mark notifications as seen ( client side )
   */
  markNotifsAsSeenInUI(): void {
    this.notifications = this.notifications.map((notification: Notification) => ({ ...notification, isSeen: true }));
  }

  /**
   * Toggle notification menu
   */
  toggleMenu(): void {
    // Mark notifications as seen ( server side )
    this.markNotifsAsSeenOnAPI();

    // Case: Closing the menu => Mark notifications as seen on the client side immediately, without waiting for the next interval to fetch and update the notifications list.
    if (this.menuIsOpened) this.markNotifsAsSeenInUI();

    this.menuIsOpened = !this.menuIsOpened;
  }

  /**
   * Open notification details dialog
   * @param notification - the target notification object
   */
  openDetails(notification: Notification): void {
    // Case: notif details component is found
    if (Object.keys(detailsComponents).includes(notification.type)) {
      this.notifDetailsIsDisplayed = true;
      // open dialog
      const dialogRef = this.matDialog.open(detailsComponents[notification.type], {
        width: '700px',
        panelClass: 'fy-panel',
        hasBackdrop: true,
        autoFocus: true,
        data: {
          id: notification.action.resource
        }
      });

      this.detailsDialogAfterClose$ = dialogRef.afterClosed().subscribe({
        next: () => {
          this.notifDetailsIsDisplayed = false;
        }
      });
    }
  }

  unsubscribe(): void {
    this.intervalSub$.unsubscribe();
    this.loadNotificationsSub$.unsubscribe();
    this.markNotifsAsSeenSub$.unsubscribe();
    this.detailsDialogAfterClose$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
