import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  @Input() status: string;
  color: string;

  constructor() {}

  ngOnInit() {
    switch (this.status) {
      case 'pending':
        this.color = '#ff9800';
        break;
      case 'accepted':
        this.color = '#4caf50';
        break;
      case 'rejected':
        this.color = '#f44336';
        break;
      default:
        this.color = '#e0e0e0';
        break;
    }
  }
}
