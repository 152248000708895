type Notification = {
  _id: string;
  type: string;
  logoUrl: string;
  receiver: string;
  channels: string[];
  isSeen: boolean;
  content: {
    en: string;
    fr: string;
  };
  action: {
    resource: string;
  };
  elapsedTime?: {
    en: string;
    fr: string;
  };
  createdAt: Date;
  updatedAt: Date;
};

enum NotificationTypes {
  DAILY_TRANSFER_SUCCESSFUL_PAYMENT = 'DAILY_TRANSFER_SUCCESSFUL_PAYMENT',
  DAILY_TRANSFER_CANCELLED_PAYMENT = 'DAILY_TRANSFER_CANCELLED_PAYMENT'
}

enum NotificationChannels {
  BACK_OFFICE = 'BACK_OFFICE',
  LYZI_PRO_APP = 'LYZI_PRO_APP'
}

export { Notification, NotificationTypes, NotificationChannels };
